$font-size-primary: '16px';

:root {
  --font-size-primary: $font-size-primary;
}

$font-size-caption-huge: 3.75rem; // menu

$font-size-caption-big: 3rem; // h1 48px
$font-size-caption-regular: 1.75rem; // h2 30px
$font-size-caption-medium: 1.625rem; // h3 26px
$font-size-caption-small: 1.375rem; // h3 22px

$font-size-text-regular: 1.062rem; // p 18px

$font-size-text-small: 1rem; // h4 16px
$font-size-text-xs: 0.938rem; //  15px
$font-size-text-tiny: 0.9rem; //  14px
$font-size-text-tiny: 0.9rem; //  14px
$font-size-text-xxs: 0.75rem; //  12px

$line-height-tiny: 1.4rem;
$line-height-small: 1.5rem;
$line-height-medium: 1.562rem;
$line-height-big: 2.125rem;
$line-height-huge: 3.5rem;
