.section-events-row {

  .events-row {
    position: relative;
    width: 100%;
    height: 24rem;
    overflow: hidden;

    .events-row-root {
      position: relative;
      width: 100%;
      height: 28rem;
      overflow-x: scroll;
      overflow-y: hidden;

      .event-row-wrapper {
        display: flex;
        height: 20rem;
        margin-left: 1rem;
        padding-right: 1rem;

        .event-item-wrapper {
          display: flex;
          width: 20rem;
          min-width: 20rem;
          max-width: 20rem;
          margin-left: 1rem;
          margin-right: 1rem;
          height: 20rem;
          background-color: #fff;
          overflow: hidden;
          border-radius: 0.75rem;
          box-shadow: 1px 1px 60px rgba(73, 13, 120, 0.08);

          .event-item-image {
            width: 20rem;
            height: 20rem;
            margin-left: -20rem;
            overflow: hidden;

            img {
              width: 100%;
              height: auto;
            }
          }

          .event-item-info {
            width: 20rem;
            height: 20rem;
            overflow: hidden;

          }
        }
      }
    }
  }
}
