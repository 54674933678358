.section-client {
  text-align: center;
  width: 80%;
  margin-left: 10%;
  position: fixed;
  top: 80vh;
  will-change: transform;

  .client-list {
    @include mixFlexAlignJusty(center, center);
    grid-gap: 2.5em;
    margin: 0 auto;
    margin-top: 3em;
    width: 80%;
    will-change: transform;

    svg {
      will-change: transform;
    }

    @include mixBreakPointMD {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 80%;
    }
    @include mixBreakPointSM {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
