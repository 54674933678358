.block-page-text-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  will-change: transform;

  .image-container {
    direction: ltr;
    width: 100%;
    height: 100%;
  }

  .text-container {
    direction: ltr;
    width: 100%;
    margin: 0;
    text-align: left;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .text-wrapper {
      width: 60%;
      padding: 2rem;

      .content-header {

      }
      .content-blocks {
        img {
          will-change: transform;
        }
      }
      .content-grid-primary {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        row-gap: 2rem;
        margin-top: 2.2rem;

        .content-block-item {

          max-width: 8.5rem;

          img {
            width: 2.4rem;
          }
          p {
            font-size: $font-size-text-tiny;
            line-height: $line-height-tiny;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}

.block-page-text-image-vertical {
  display: block;
  grid-template-columns: 1fr;
  width: 80%;
  margin-left: 10%;
  padding-top: 10%;
  padding-bottom: 10%;

  .image-container {
    padding: 0;
    margin-left: 0;
    width: 100%;
  }

  .text-container {
    margin: 0;
    padding: 0;
    text-align: left;
    height: auto;

    .text-wrapper {
      width: 100%;
      padding: 0;
    }
  }
}
