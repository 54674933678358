.app-header-logotype {
  position: fixed;
  left: 0;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: 950;
  margin: 1.25rem;
  mix-blend-mode: difference;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.mobile-burger {
  display: block;
  position: fixed;
  cursor: pointer;
  transform: scaleX(0.8) scaleY(0.8) translate(23px, -26px);
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
  z-index: 950;
  mix-blend-mode: difference;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);

  .line--1,
  .line--3 {
    --total-length: 111.22813415527344;
    --offset: -50.22813415527344;
  }
  .line--2 {
    --total-length: 99;
  }

  path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all .5s cubic-bezier(.645, .045, .355, 1);
  }
}

.mobile-burger-open {
  path {
    transform: translateX(30px);
  }
  .line--1,
  .line--3 {
    /*
    --offset: -16.9705627485;
    --length: 22.627416998;
     */
    --offset: -14.7;
    --length: 26.7;
  }
  .line--2 {
    --offset: -20;
    --length: 0;
  }
}

.mobile-menu {
  position: fixed;
  z-index: 948;
  top: 0;
  width: 100%;
  height: 100%;

  .menu-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    @include mixAnimationPrimary([background-color, opacity, transform]);
  }

  .menu-item-wrapper {
    background-color: #000000;
  }

  transform: translateX(-100%);
  @include mixAnimationPrimary([background-color, opacity, transform]);

  .menu-item-wrapper {
    @include mixAnimationPrimary([background-color, opacity, transform]);
    position: absolute;
    z-index: 950;
    transform: translateX(400%);
    text-align: right;
    width: 100%;

    a {
      font-size: 3.75rem;
      font-family: $fonts-primary-semi-bold;
      display: block;
      padding: 2rem;
      border-bottom: 1px solid #F7F8FA;
    }
    a:first-child {
      margin-top: 4rem;
    }
    a:last-child {
      border: none;
    }
  }
}

.mobile-menu-open {
  transform: translateX(0);
  .menu-background {
    transform: translateX(0);
  }
  .menu-item-wrapper {
    transform: translateX(0);
    color: #fff;
  }
}
