.page-section-fixed {
  width: 100%;
  position: relative;
  min-height: 100vh;

  .background-video {
    object-fit: cover;
    width: 100vw;
    height: 110vh;
    margin: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 120%;
    z-index: -1;
  }

  .background-image {
    object-fit: cover;
    width: 100vw;
    height: 110vh;
    margin: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 120%;
    z-index: -1;

    img {
      height: 100%;
    }
  }
}

.page-section {
  position: relative;
  width: 100%;
  min-height: 140vh;
  @include mixAnimationSecondary([transform]);

  .page-section-wrapper {

    position: fixed;
    top: 0;
    min-height: 100vh;

    .content {
      position: absolute;
      top: 0;
      z-index: 2;
      color: #fff;
    }
    .background-image {
      object-fit: cover;
      width: 100vw;
      height: 110vh;
      margin: 0;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 120%;
      z-index: -1;
    }
    .background-video {
      object-fit: cover;
      width: 100vw;
      height: 110vh;
      margin: 0;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 120%;
      z-index: -1;
    }
  }
}
.page-section-over {
  overflow: hidden;
}
.page-section-white {
  background-color: white;
}
.page-section-green {
  background-color: greenyellow;
}
.page-section-black {
  color: white;
  background-color: black;
}
