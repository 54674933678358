.button-large {
  display: inline-block;
  text-align: center;
  width: 10rem;
  font-size: $font-size-text-small;

  button {
    position: relative;
    display: inline-block;
    border-radius: 3.125rem;
    width: 100%;
    height: 3.75rem;
    padding-left: 100px;
    padding-right: 100px;
    filter: drop-shadow(2px 2px 160px rgba(131, 72, 255, 0.24));
    background-color: #FFF;
  }
}
