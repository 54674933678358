.privacy-page-wrapper {
  width: 100%;
  background: #F7F8FA;
  display: flex;
  align-items: center;
  flex-direction: column;
  .page-container{
    padding: 1rem;
    margin-top: 100px;
    max-width: 760px;
    margin-bottom: 42px;
    height: 100%;
    width: 100%;
  }
  .page-header{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .page-title{
      color: #000000;
      font-size: $font-size-caption-medium;
      font-family: $fonts-primary-medium;
      font-weight: 500;
      line-height: 1.6;
      margin: 0 0 2px;
    }
    .page-subtitle{
      color: #818181;
      font-size: $font-size-text-xxs;
      font-family: $fonts-primary-medium;
      font-weight: 500;
      margin: 0;
      line-height: 1.6
    }
  }
}

.privacy-text{
  margin-bottom: 15px;
  font-size: $font-size-text-xs;
  font-family: $fonts-primary-medium;
  color: #000000;
}

.privacy-section-wrapper{
  margin-bottom: 3rem;
}

.privacy-section-title{
  font-weight: 600;
  margin: 0 0 4px 0;
  font-size: $font-size-text-regular;
  font-family: $fonts-primary-semi-bold;
  color: #000000;
  line-height: 1;
  height: 30px;
  display: flex;
  align-items: center;
}
