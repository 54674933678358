.block-page-cover {
  width: 50%;
  margin-left: 10%;
  height: 100%;
  display: flex;
  align-items: center;

  @include mixBreakPointXL {
    width: 40%;
  }
}

.block-page-cover-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    object-fit: cover;
    object-position: 10%;
  }

  p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.block-page-cover-centered {
  width: 40%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.block-page-cover-component {
  margin-left: 5%;
  width: 90%;
}

.block-page-cover-vertical {
  margin-left: 5%;
  width: 90%;
}

.block-page-cover-title {
  max-width: 600px;
  margin: auto 5%;

  @include mixBreakPointMD {
    max-width: 40%;
  }

  @include mixBreakPointSM {
    max-width: 50%;
  }
  .special {
    font-family: $fonts-primary-bold;
    @include mixAnimationTertiary([color, opacity]);
  }
}
