.section-description {
  width: 100%;
  padding: 3.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .section-description-wrapper {

    .description-section-block-title {
    }
    .description-section-block-cards-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 10rem 0;
      gap: 1rem;
    }
  }
}
