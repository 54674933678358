@mixin mixBreakPointXS {
  @media only screen and (max-width: 339px) {
    @content;
  }
}

@mixin mixBreakPointSM {
  @media only screen
  and (max-width: 599px) {
    @content;
  }
}

@mixin mixBreakPointMD {
  @media only screen
  and (max-width: 899px) {
    @content;
  }
}

@mixin mixBreakPointLG {
  @media only screen
  and (max-width: 1199px) {
    @content;
  }
}

@mixin mixBreakPointXL {
  @media only screen
  and (min-width: 1200px) {
    @content;
  }
}

@mixin mixBreakMinHeight {
  @media only screen
  and (min-height: 640px) {
    @content;
  }
}
