.base-select {
  display: inline-block;
  min-width: 250px;
  text-align: center;
  position: relative;
  background: white;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 10px;
  height: 46px;
  padding: 0 16px;
  box-sizing: border-box;
}

.error-select {
  @extend .base-select;
  border: 1px solid red;
}

.custom-select-container {
  @extend .base-select;
  border: 1px solid #F7F7F7;
}

.select-base-button {
  padding: 0;
  background: white;
  font-size: $font-size-text-small;
  font-family: $fonts-primary-medium;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-text {
  @extend .select-base-button;
  color: black;
}

.selected-text-error {
  @extend .select-base-button;
  color: red;
}

.selected-text::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 16px;
}

.selected-text.active::after {
  top: 8px;
  border-color: transparent transparent #fff transparent;
}
.error-select, .custom-select-container {
  ul {

  }

  .select-options {
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    left: 0;
    box-shadow: 0 0 24px 0 #00000014;
    border-radius: 24px;
    text-align: left;
  }

  .custom-select-option {
    list-style-type: none;
    padding: 12px 16px;
    color: #000000;
    font-weight: 500;
    font-size: $font-size-text-small;
    font-family: $fonts-primary-medium;
    background: white;
    cursor: pointer;
  }

  .custom-select-option:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid transparent;
  }

  .custom-select-option:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid transparent;
  }

  .custom-select-option:hover {
    background-color: #F6F8FB;
    color: #000000;
  }
}

.select-default-text{
  font-weight: 500;
  color: #A7A7A9;
}

