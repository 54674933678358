.section-container-default {
  width: 100%;
  min-height: 100vh;

  .section-background {
    position: absolute;
    top: 0;
    z-index: 0;

    .background-video {
      width: 100%;
      height: 100vh;
    }
  }
}

.section-container-fixed {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
 }

.section-container-static {
  width: 100%;
}

.section-container-sticky {
  width: 100%;

  .section-container-sticky-wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
}

.section-container-full-screen {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.page-section-fixed {
  display: none;
}
