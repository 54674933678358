$primary-animation-time: 0.2s;
$secondary-animation-time: 0s;
$tertiary-animation-time: 1s;
$primary-animation-style: $primary-animation-time ease-in-out;
$secondary-animation-style: $secondary-animation-time linear;

@function primaryAnim($variable) {
  @return $variable $primary-animation-style;
}
@function secondaryAnim($variable) {
  @return $variable $secondary-animation-style;
}
@function tertiaryAnim($variable) {
  @return $variable $tertiary-animation-time;
}

@mixin mixAddWebkit($variable) {
  -webkit-transition: $variable;
  -moz-transition: $variable;
  -o-transition: $variable;
  transition: $variable;
}

@mixin mixAnimationPrimary($properties) {
  $animationString: ();
  @each $property in $properties {
    $animationString: $animationString , primaryAnim($property)
  }
  @include mixAddWebkit($animationString);
}

@mixin mixAnimationSecondary($properties) {
  $animationString: ();
  @each $property in $properties {
    $animationString: $animationString , secondaryAnim($property)
  }
  @include mixAddWebkit($animationString);
}

@mixin mixAnimationTertiary($properties) {
  $animationString: ();
  @each $property in $properties {
    $animationString: $animationString , tertiaryAnim($property)
  }
  @include mixAddWebkit($animationString);
}
