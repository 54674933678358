.block-text-image {
  will-change: transform;

  .block-text {
    position: fixed;
    width: 50%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;

    .block-text-wrapper {
      width: 50%;
    }
  }
  .block-image {
    position: fixed;
    will-change: transform;
    top: 0;
    width: 50%;
  }

  @include mixBreakPointMD {

    .block-image {
      position: relative;
      width: 100%;
    }
    .block-text {
      position: fixed;
      width: 80%;
      height: 50%;
      align-items: flex-start;
      left: 10%;
      top: 60vh;
      .block-text-wrapper {
        width: 100%;
      }
    }
  }
}
