.button-email {
  padding: 1rem 7rem;
  border-radius: 3.125rem;
  color: #000000;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 60px rgba(73, 13, 120, 0.08);
  font-family: $fonts-primary-semi-bold;
  font-size: $font-size-text-regular;

  @include mixBreakPointSM {
    min-width: 50%;
    padding: 1rem 3rem;
  }
}
