.footer-primary {
  width: 100%;
  z-index: 900;
  position: relative;
  background-color: $color-background-primary;
  margin: 0 auto;
  padding-top: 2.5em;
  padding-bottom: 2.5em;

  .logotype {
    height: 2rem;
  }
  .navigation {
    margin-top: 1em;

    a {
      font-size: $font-size-text-regular;
      font-family: $fonts-primary-medium;
      margin: 1em;

      @media only screen
      and (max-width: 18rem) {
        display: block;
      }
    }
  }
  .social-wrapper {
    @include mixFlexAlignJusty(center, center);
    margin-top: 0.2em;
    grid-gap: 0.4em;
    .social-icons {
      margin-top: 1.2rem;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.6rem;
      height: 1.375rem;
    }
  }
  .copyright {
    margin-top: 1.4em;
  }

  .good-firm-logo {
    margin-top: 1.4em;
  }

}

.footer-primary-full-screen {
  @include mixBreakMinHeight {
    position: absolute;
    bottom: 0;
  }
}
