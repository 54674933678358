.app-container-primary {
  height: 100%;
  position: relative;
  @include mixAnimationPrimary([opacity]);
}

.app-container-full-screen{
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  @include mixBreakMinHeight {
    position:fixed;
    padding: 0;
  }
}
