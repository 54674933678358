.team-member-container{
  @apply tw-bg-grayLight tw-flex-col tw-justify-start tw-w-full tw-h-full  tw-py-32
}

.section-wrapper{
  @apply tw-w-full tw-pb-10 md:tw-pb-24
}

.section-container{
  @apply tw-max-w-md tw-px-4 md:tw-px-0 tw-mx-auto md:tw-max-w-2xl
}

.member-image-wrapper{
  @apply tw-mb-4 tw-w-24 tw-h-24 md:tw-w-120 md:tw-h-120 tw-rounded-full tw-border
}

.skill-category{
  @apply tw-mb-1 tw-h-6 tw-flex tw-items-center tw-text-xxs tw-font-regular
}

.skills-wrapper{
  @apply tw-flex tw-flex-row tw-flex-wrap tw-items-center
}

.skill-item{
  @apply tw-mb-1 tw-ml-1 tw-text-xxs tw-font-medium tw-bg-gradient-to-r tw-from-purpleLightFrom tw-to-purpleLightTo tw-px-3 tw-py-2 tw-rounded-3xl
}
