.block-image {
  position: relative;

  img {
    position: absolute;
    will-change: transform;
  }
}

.block-image-container {

  img {
    @include mixAnimationTertiary([opacity]);
    opacity: 0;
    will-change: transform;
  }
}
