.block-cover-title-primary {
  position: fixed;
  z-index: 20;
  width: 50%;
  margin-left: 10%;
  height: 100%;
  display: flex;
  align-items: center;

  @include mixBreakPointMD {
    text-align: center;
    width: 80%;
  }
  @include mixBreakPointXL {
    width: 40%;
  }
}
