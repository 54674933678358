.section-two-blocks {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10%;
  width: 100%;

  .block-image {
    width: 40%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .block-text {
    width: 40%;
  }

  @include mixBreakPointMD {
    flex-direction: column;

    .block-image {
      width: 80%;
    }
    .block-text {
      width: 80%;
    }
  }
}


.section-two-blocks-left {
  @include mixBreakPointMD {
    flex-direction: column-reverse;
  }
}
