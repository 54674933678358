.page-event {
  .content-header {
    text-align: center;
    margin-bottom: 4rem;
  }
}
.events-row {
  position: relative;
  width: 100%;
  height: 35rem;
  overflow: hidden;
  will-change: transform;

  .events-row-root {
    position: relative;
    width: 100%;
    height: 40rem;
    overflow-x: scroll;
    overflow-y: hidden;

    .event-row-wrapper {
      display: flex;
      height: 20rem;
      margin-top: 8rem;

      .event-item-wrapper {
        display: flex;
        position: relative;
        width: 20rem;
        min-width: 20rem;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 20rem;
        background-color: #fff;
        overflow: hidden;
        border-radius: 0.75rem;
        box-shadow: 1px 1px 60px rgba(73, 13, 120, 0.08);
        @include mixAnimationPrimary([width, left]);


        .event-item-image {
          width: 20rem;
          height: 20rem;
          margin-left: -20rem;
          overflow: hidden;
          @include mixAnimationPrimary([margin-left]);

          img {
            width: auto;
            height: 100%;
          }
        }

        .event-item-info {
          display: block;
          width: 20rem;
          height: 20rem;
          text-align: center;
          overflow: hidden;
        }
      }
      .event-item-wrapper-open {
        width: 40rem;
        min-width: 40rem;
        .event-item-image {
          margin-left: 0;
        }
      }
      .item-vertical {
        .event-item-image {
          width: 20rem;
          height: 20rem;
          margin-left: -20rem;
          overflow: hidden;
          @include mixAnimationPrimary([margin-left]);

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}
