.block-page-text  {
  text-align: center;
  width: 80%;
  margin-left: 10%;
  will-change: transform;

  .page-content {
    padding-top: 5rem;
    padding-bottom: 5rem;
    .content-header {
      text-align: center;
      margin-bottom: 4rem;
      padding: 0 10%;
    }
    .content-blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media only screen and (max-width: 50rem) {
        grid-template-columns: 1fr;
      }
      gap: 1em;
      row-gap: 4rem;
      .content-block-item {
        max-width: 20rem;
        margin: 0 auto;
        img {
          width: 3.125rem;
        }
        h3 {
          margin-bottom: 0.4rem;
        }
      }
    }

    .content-blocks-triple {
      grid-template-columns: 1fr 1fr 1fr;
      @media only screen and (max-width: 50rem) {
        grid-template-columns: 1fr;
      }
    }

    .content-footer {
      margin-top: 4rem;
      padding: 0 10%;
    }
  }
}

.block-page-text-vertical {

  .text-container {
    margin-left: 2em;
    width: 100%;
    text-align: left;
    height: auto;
  }
  .page-content {
    .content-header {
      padding: 0;
    }
    .content-footer {
      padding: 0;
    }
  }
}
