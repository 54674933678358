html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  scroll-behavior: smooth;
  font-size: var(--font-size-primary);
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $color-background-primary;
  font-family: $fonts-primary-regular;
  transition-duration: 100ms;
}

html, body, #root {
  height:100%;
  min-height: 100%;
}

#root {
  max-width: 100%;
}

body, input, textarea, select, button {
  font-synthesis: none;
  -moz-font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: $font-size-caption-big;
  font-family: $fonts-primary-bold;
  line-height: $line-height-huge;
  margin-bottom: 1.125rem;
}

h2 {
  font-size: $font-size-caption-regular;
  font-family: $fonts-primary-semi-bold;
  margin-bottom: 1rem;
}

h3 {
  margin-top: 0.75rem;
  font-size: $font-size-caption-small;
  font-family: $fonts-primary-semi-bold;
  margin-bottom: 0;
}

h4 {
  font-size: $font-size-text-small;
  font-family: $fonts-primary-semi-bold;
  margin-bottom: 0;
}

h5 {
  font-size: $font-size-text-regular;
  font-family: $fonts-primary-semi-bold;
  margin-bottom: 0.25rem;
}

h6 {
  margin-top: 0.75rem;
  font-size: $font-size-text-small;
  margin-bottom: 0;
}

p {
  margin: 0;
  font-size: $font-size-text-regular;
  line-height: $line-height-medium;
}

span {
  font-size: $font-size-caption-small;
  font-family: $fonts-primary-semi-bold;
  line-height: $line-height-big;
}

a, img, a:link, a:visited, a:hover, a:focus, button, input, input:focus, select, select:focus {
  border: none;
  outline: none;
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  filter: none;
  @include mixAnimationPrimary([color, background-color, opacity]);
}

button, a {
  cursor: pointer;
}

div {
  box-sizing: border-box;
}
