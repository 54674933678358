.block-page-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
  gap: 2rem;
  align-items: center;
  will-change: transform;

  .image-container {
    direction: ltr;
    width: 100%;
    height: 100%;
  }

  .text-container {
    direction: ltr;
    padding: 1em;
    width: 80%;
    margin: 0;
    text-align: left;
    height: auto;
    h2 {
      width: 60%;
    }
  }
}

.block-page-project-vertical {
  display: block;
  grid-template-columns: 1fr;
  text-align: center;

  .image-container {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .text-container {
    margin: 0;
    width: 80%;
    margin-right: 10%;
    padding: 1rem;
    text-align: left;
    height: auto;
    h2 {

    }
  }
}
