.section-full-screen {
  @include mixFlexAlignJusty(center, center);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section-container-full-screen-content {
  height: 100%;
}

.section-container-full-screen-wrapper {
  height: 100%;
}
