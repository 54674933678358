.block-page-icons {
  text-align: center;
  width: 80%;
  margin-left: 10%;
  will-change: transform;

  .icon-list {
    @include mixFlexAlignJusty(center, center);
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2.5rem;
    margin: 3rem auto 0 auto;

    @media only screen
    and (min-width: 80rem) {
      display: grid;
      grid-auto-flow: column;
    }

    @media only screen
    and (max-width: 64rem) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 80%;
    }

    @media only screen
    and (max-width: 32rem) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 1.2rem;
    }

    @media only screen
    and (max-width: 18rem) {
      display: grid;
      grid-template-columns: 1fr;
      font-size: 1.5rem;
    }
  }
}
