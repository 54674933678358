.app-container-secondary {
  min-height: 100%;
  background-color: $color-background-tertiary;
  @include mixAnimationPrimary([opacity]);
  @include mixFlexAlignJusty(center, center);

  .container {
    text-align: center;
    padding: 1rem;

    .icon {;
      width: 80%;
      max-width: 40rem;
      max-height: 50%;
    }

    button {
      max-width: 100%;
      margin-top: 3rem;
      padding: 1rem 3rem;

      @include mixBreakPointSM {
        min-width: 50%;
        padding: 1rem 3rem;
      }
    }
  }
}
