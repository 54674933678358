.block-title {
  text-align: center;
  max-width: 66%;
  margin: 0 auto;

  @include mixBreakPointSM {
    max-width: 90%;
  }
  .special {
    font-family: $fonts-primary-bold;
    @include mixAnimationTertiary([color, opacity]);
  }
}
