.section-text-image-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  height: 180vh;
  width: 80%;
  margin: 0 auto;

  @include mixBreakPointMD {
    grid-template-columns: 1fr;
    height: auto;
    overflow: hidden;
  }

  .visual-part {
    position: absolute;
    top: 0;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    @include mixBreakPointMD {
      width: 80%;
      height: auto;
      position: relative;
    }
  }
  .text-part-wrapper {
    position: fixed;
    top: 0;
    align-items: center;
    height: 200vh;

    @include mixBreakPointMD {
      width: 80%;
      height: auto;
      position: relative;
    }
  }
}

.section-text-image-project {

  width: 100%;
  margin: 0 auto;

  .visual-part {
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 100vh;
  }
}

.visual-left {
  margin-left: 0;
  margin-right: 10%;

  .visual-part {
    div {
      background-position: left bottom;
    }
  }
}
