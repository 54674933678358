.title-section-contact-btn-wrapper{
  margin-top: 12rem;
  display: flex;
  justify-content: center;
}

.page-wrapper {
  padding: 6.5rem 20px;
  width: 100%;
  background: #F7F8FA;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .page-title{
    color: #000000;
    font-size: $font-size-caption-medium;
    font-family: $fonts-primary-medium;
    font-weight: 500;
    line-height: 1.6;
    margin: 0 0 2px;
  }
  .page-subtitle{
    color: #000000;
    font-size: $font-size-text-small;
    font-family: $fonts-primary-medium;
    font-weight: 500;
    margin: 0;
    line-height: 1.6
  }
  .form-container{
    margin-top: 45px;
    max-width: 560px;
    display: flex;
    flex-direction: column;
  }

  .form-error-input{
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid red;
    border-radius: 10px;
    height: 46px;
    padding: 0 16px;
    box-sizing: border-box;
    font-family: $fonts-primary-medium;
    font-size: $font-size-text-small;
    color: red;
  }

  .form-input{
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid #F7F7F7;
    border-radius: 10px;
    height: 46px;
    padding: 0 16px;
    box-sizing: border-box;
    font-family: $fonts-primary-medium;
    font-size: $font-size-text-small;
    color: black;
  }

  .form-textarea{
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid #F7F7F7;
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    font-family: $fonts-primary-medium;
    font-size: $font-size-text-small;
    color: black;
  }

  .form-error-textarea{
    width: 100%;
    margin-bottom: 8px;
    border: 1px solid red;
    border-radius: 10px;
    padding: 16px;
    box-sizing: border-box;
    font-family: $fonts-primary-medium;
    font-size: $font-size-text-small;
    color: red;
    outline: none;
  }

  .form-textarea:focus-visible{
    border: 1px solid #F7F7F7;
    outline: none;
  }

  .form-error-textarea::placeholder, .form-error-input::placeholder {
    font-weight: 500;
    color: red;
  }

  .form-textarea::placeholder, .form-input::placeholder {
    font-weight: 500;
    color: #A7A7A9;
  }
}

.contact-btn-wrapper{
  margin-top: 40px;
  margin-bottom: 48px;
  text-align: center;
}

.contact-button{
  border-radius: 3.125rem;
  color: #000000;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 60px rgb(73 13 120 / 8%);
  font-family: "gilroy-semibold", serif;
  font-size: 1.062rem;
  width: 224px;
  height: 52px;
  min-height: 52px;
}

.contact-button:hover {
  opacity: 0.6;
}

.request-error{
  margin-top: 10px;
  text-align: center;
  color: red;
  font-family: $fonts-primary-medium;
  font-size: $font-size-text-small;
}

.request-success{
  margin-top: 10px;
  text-align: center;
  color: #000000;
  font-family: $fonts-primary-medium;
  font-size: $font-size-text-small;
}

.bottom-subtitle{
  color: #000000;
  font-size: $font-size-text-xxs;
  font-family: $fonts-primary-medium;
  font-weight: 600;
  margin: 0 0 3.5rem 0;
  line-height: 1.6;
  a {
    font-weight: bold;
    text-decoration: underline;
    color:#4643DB ;
  }
}

.success-page-wrapper{
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-page-title{
  color: #000000;
  font-size: 2rem;
  font-family: $fonts-primary-semi-bold;
  margin: 2rem 0 0 0;
}

.success-page-subtitle{
  color: #000000;
  font-size: 1rem;
  font-family: $fonts-primary-medium;
  margin: 2px 0 3.5rem 0;
}

.back-button{
  margin-bottom: 20px;
}
