.button-with-arrow {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  margin-top: 2rem;

  span {
    font-family: $fonts-primary-medium;
    font-size: $font-size-text-tiny;
  }
  svg {
    margin-top: 0.25rem;
  }
}
